<template>
 
  <section id="dashboard-analytics">
    <!-- <b-row class="match-height">
      <b-col xl="4" md="4" sm="6">
        <total-device />
      </b-col>
      <b-col xl="4" md="4" sm="6">
        <device-online />
      </b-col>
      <b-col xl="4" md="4" sm="6">
        <device-offline />
      </b-col>
    </b-row> -->

    <b-row>
      <b-col lg="4">
        <!-- <b-row>
      
          <b-col lg="12" md="6">
            <total-credit />
          </b-col>
        
        </b-row> -->
      </b-col>

      <!-- Revenue Report Card -->
      <b-col lg="8">
        <device-customer />
      </b-col>
      <!--/ Revenue Report Card -->
    </b-row>

    <!-- <b-row class="match-height">
      <b-col xl="4" md="4" sm="6"> </b-col>
      <b-col xl="4" md="4" sm="6"> </b-col>
      <b-col xl="4" md="4" sm="6"> </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col xl="4" sm="6"> </b-col>
      <b-col xl="4" sm="6"> </b-col>
      <b-col xl="4" sm="6"> </b-col>
    </b-row> -->

    <!-- <b-row class="match-height">
      <b-col xl="4" sm="6">
        <seat-checkin />
      </b-col>
      <b-col xl="4" sm="6">
        <company-checkin />
      </b-col>
    </b-row> -->

    <!-- <b-row lg="12" class="match-height">
      <b-col lg="3">
        <name-company />
      </b-col>
      <b-col lg="9">
        <all-vehicle /> -->
    <!-- <statistic-card-with-area-chart
          icon="UsersIcon"
          statistic="100"
          statistic-title="ลูกค้าทั้งหมด"
          :chart-data="data.CuschartData"
          /> -->
    <!-- </b-col>
    </b-row> -->

    <!-- <b-col
            lg="3"
            sm="6"
        >
            <statistic-card-with-area-chart
            icon="TruckIcon"
            color="warning"
            statistic="500"
            statistic-title="รถทั้งหมด"
            :chart-data="data.CarschartData"
            />
        </b-col> -->

    <!-- <b-row class="match-height">
      <b-col lg="12" md="12">
        <topten-checkin />
      </b-col>
    </b-row> -->
  </section>
  <!-- </div> -->
  <!-- <div v-else>{{ doLog }}</div> -->
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";

import { kFormatter } from "@core/utils/filter";

import StatisticCardVertical from "@core/components/statistics-cards/StatisticCardVertical.vue";
import deviceCustomer from "./deviceCustomer.vue";
import TotalDevice from "./TotalDevice.vue";
import DeviceOnline from "./DeviceOnline.vue";
import DeviceOffline from "./DeviceOffline.vue";
import addCredit from "./addCredit.vue";
import disCredit from "./disCredit.vue";
import DeviceCustomer from "./deviceCustomer.vue";
import TotalCredit from "./totalCredit.vue";
import AddCredit from "./addCredit.vue";
import DisCredit from "./disCredit.vue";

export default {
  components: {
    BRow,
    BCol,
    StatisticCardVertical,
    deviceCustomer,
    TotalDevice,
    DeviceOnline,
    DeviceOffline,
    addCredit,
    disCredit,
    DeviceCustomer,
    TotalCredit,
    AddCredit,
    DisCredit,
  },
  data() {
    return {
      data: {},
      role: "",
    };
  },
  created() {
    // data
    // this.$http.get('/analytics/data')
    //   .then(response => { this.data = response.data })
  },
  methods: {
    kFormatter,
    // doLog() {
    //   router.push({ path: 'home' })
    // },

    // showtoken() {
    //   var token = localStorage.getItem("token");
    //   var decoded = jwt_decode(token);

    //   this.currentUserId = decoded.sub;
    //   this.role = decoded.role;
    //   if (decoded.role == "admin") {
    //     this.cusCode = decoded.cusCode;
    //   } else {
    //     this.cusCode = decoded.cusCode;
    //   }
    // },
    // async created() {
    //   await this.showtoken();
    // },
  },
};
</script>
