import http from "./http";

export default {
    getalldata(filterAll) {
        return http
            .get("product" + filterAll)
            .then(function (response) {
                return Promise.resolve(response);
            })
            .catch(function (error) {
                return Promise.reject(error);
            });
    },

    getdata(filterAll) {
        return http
            .get("device" + filterAll)
            .then(function (response) {
                return Promise.resolve(response);
            })
            .catch(function (error) {
                return Promise.reject(error);
            });
    },


    getdataById(id) {
        return http
            .get("product/" + id)
            .then(function (response) {
                return Promise.resolve(response);
            })
            .catch(function (error) {
                return Promise.reject(error);
            })
    },
    getProductOptionsdata() {
        return http
            .get("product-options")
            .then(function (response) {
                return Promise.resolve(response);
            })
            .catch(function (error) {
                return Promise.reject(error);
            })
    },
    getProSetOptByCus(id) {
        return http
            .get("product-setup/getOpt/" + id)
            .then(function (response) {
                return Promise.resolve(response);
            })
            .catch(function (error) {
                return Promise.reject(error);
            })
    },
    insertdata(form) {
        return http
            .post("product", form)
            .then(function (response) {
                return Promise.resolve(response);
            })
            .catch(function (error) {
                return Promise.reject(error);
            });
    },
    updatedata(id, form) {
        return http
            .put("product/" + id, form)
            .then(function (response) {
                return Promise.resolve(response);
            })
            .catch(function (error) {
                return Promise.reject(error);
            });
    },
    updatecustomer(id, form) {
        return http
            .put("adminCustomer/" + id, form)
            .then(function (response) {
                return Promise.resolve(response);
            })
            .catch(function (error) {
                return Promise.reject(error);
            });
    },
    deletedata(id) {
        return http
            .delete("product/" + id)
            .then(function (response) {
                return Promise.resolve(response);
            })
            .catch(function (error) {
                return Promise.reject(error);
            });
    },
    getdataadmin() {
        return http
            .get("mapping_product")
            .then(function (response) {
                return Promise.resolve(response);
            })
            .catch(function (error) {
                return Promise.reject(error);
            })
    },
    getdatacustomer(id) {
        return http
            .get("detailproduct/" + id)
            .then(function (response) {
                return Promise.resolve(response);
            })
            .catch(function (error) {
                return Promise.reject(error);
            })
    },
    searchall(sortdata, searchdata, page) {
        return http
            .get("s_product?sort=" + sortdata + "&s=" + searchdata + "&page=" + page)
            .then(function (response) {
                return Promise.resolve(response);
            })
            .catch(function (error) {
                return Promise.reject(error);
            });
    },
    sortbyid(sortdata, searchdata, page) {
        return http
            .get("s?sort=" + sortdata + "&s=" + searchdata + "&page=" + page)
            .then(function (response) {
                return Promise.resolve(response);
            })
            .catch(function (error) {
                return Promise.reject(error);
            });
    },
}