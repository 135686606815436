<template>
  <b-row class="match-height">
    <b-col>
      <b-card>
        <b-card-header>
          <h1 class="font-midium-1 font-weight-bolder text-primary">
            1 เครื่อง
          </h1>
          <b-col lg="3" sm="6">
            <b-avatar rounded="circle" size="50" variant="success">
              <feather-icon
                icon="CheckCircleIcon"
                variant="secondary"
                size="30"
              /> </b-avatar
          ></b-col>
        </b-card-header>
        <b-card-body><h4>จำนวนเครื่องที่ออนไลน์</h4></b-card-body>
      </b-card>
    </b-col>
  </b-row>
</template>
<script>
import {
  BCardBody,
  BMedia,
  BMediaAside,
  BMediaBody,
  BCard,
  BRow,
  BCol,
  BButton,
  BDropdown,
  BDropdownItem,
  BProgress,
  BCardText,
  BCardHeader,
  BLink,
  BTable,
  BFormCheckbox,
  BBadge,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BAvatar,
  BImg,
  BCardTitle,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import Ripple from "vue-ripple-directive";
//import { $themeColors } from "@themeConfig";
import { kFormatter } from "@core/utils/filter";
//import jwt_decode from "jwt-decode";
import DateRangePicker from "vue2-daterange-picker";
//import reportService from "@/services/reportService.js";
//import vehiclesService from "@/services/vehiclesService.js";
//import customerService from "@/services/customerService.js";

import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
//import dateFormat from "dateformat";
//import cusDataService from "@/services/cusDataService";
import StatisticCard from "@core/components/statistics-cards/StatisticCardVertical.vue";

export default {
  components: {
    VueApexCharts,
    StatisticCard,
    BCard,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BMedia,
    BCardBody,
    BRow,
    BCol,
    BButton,
    BDropdown,
    BDropdownItem,
    BProgress,
    BCardText,
    BCardText,
    BCardHeader,
    BCardHeader,

    BLink,
    BTable,
    BFormCheckbox,

    BBadge,

    BFormGroup,
    BFormSelect,
    BPagination,
    BMediaAside,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BCardTitle,

    BImg,
    DateRangePicker,
  },
  directives: {
    Ripple,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    kFormatter,
  },
};
</script>
