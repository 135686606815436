<template>
  <div>
    <b-card>
      <b-row>
        <b-col md="3" class="my-1">
          <b-card-body><h4>รายการเครื่อง TENKO</h4></b-card-body>

          <!-- <b-form-group class="mb-0">
            <label class="d-inline-block text-sm-left mr-50">Sort</label>
            <b-input-group size="sm">
              <b-form-select
                id="sortBySelect"
                v-model="sortBy"
                :options="sortOptions"
                class="w-75"
              >
                <template v-slot:first>
                  <option value="">-- none --</option>
                </template>
              </b-form-select>
              <b-form-select
                v-model="sortDesc"
                size="sm"
                :disabled="!sortBy"
                class="w-25"
              >
                <option :value="false">Asc</option>
                <option :value="true">Desc</option>
              </b-form-select>
            </b-input-group>
          </b-form-group> -->
        </b-col>

        <b-col md="3" sm="6" class="my-1 d-none d-md-block"></b-col>
        <b-col md="6" class="my-1">
          <b-form-group class="mb-0">
            <label class="d-inline-block text-sm-left mr-50">{{ $t("Filter") }}</label>
            <b-input-group size="sm">
              <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                placeholder="Search Product ID, Title"
                @keyup="searchEnter()"
                @keydown="searchEnter()"
                @change="searchEnter()"
              />
              <b-input-group-append>
                <b-button :disabled="!filter" @click="searchClear()">
                  {{ $t("Clear") }}
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-table
            striped
            responsive
            :per-page="perPage"
            :current-page="currentPagetable"
            :items="bigdata"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
            @sort-changed="sortingChanged"
          >
            <template #cell(show_details)="row">
              <b-form-checkbox
                v-model="row.detailsShowing"
                plain
                class="vs-checkbox-con"
                @change="row.toggleDetails"
              >
                <span class="vs-checkbox">
                  <span class="vs-checkbox--check">
                    <i class="vs-icon feather icon-check" />
                  </span>
                </span>
                <span class="vs-label">{{
                  row.detailsShowing ? "Hide" : "Show"
                }}</span>
              </b-form-checkbox>
            </template>

            <template #row-details="row">
              <b-card>
                <b-row class="mb-2">
                  <b-col md="4" class="mb-1">
                    <strong>Date : </strong>{{ row.item.proDate }}
                  </b-col>
                  <b-col md="4" class="mb-1">
                    <strong>CODE : </strong>{{ row.item.proCode }}
                  </b-col>
                  <b-col md="4" class="mb-1">
                    <strong>CUSTOMER : </strong>{{ row.item.cusName }}
                  </b-col>
                  <!-- <b-col md="4" class="mb-1">
                    <strong>Status : </strong>{{ row.item.proState }}
                  </b-col> -->
                  <!-- <b-col md="12" class="mb-1">
                    <b-button
                      size="sm"
                      variant="outline-warning"
                      @click="editOpen(row.item.proId)"
                    >
                      <feather-icon icon="EditIcon" />
                      <span class="align-middle ml-50">{{ $t("Edit") }}</span> </b-button
                    >&nbsp;
                    <b-button
                      size="sm"
                      variant="outline-danger"
                      @click="deleteData(row.item.proId, row.item.cusId)"
                    >
                      <feather-icon icon="TrashIcon" />
                      <span class="align-middle ml-50">{{ $t("Delete") }}</span>
                    </b-button>
                  </b-col> -->
                </b-row>

                <!-- <b-button
                  size="sm"
                  variant="outline-secondary"
                  @click="row.toggleDetails"
                >
                  Hide Details
                </b-button> -->
              </b-card>
            </template>

            <template #cell(cusName)="row">
              <div v-if="row.item.cusId == null">
                <b-badge variant="success">Empty</b-badge>
              </div>
              {{ row.item.cusName }}
            </template>

            <template #cell(actions)="row">
              <b-dropdown
                variant="link"
                no-caret
                :right="$store.state.appConfig.isRTL"
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>

                <b-dropdown-item @click="editOpen(row.item.proId)">
                  <feather-icon icon="PowerIcon" />
                  <span class="align-middle ml-50">ShutDown</span>
                </b-dropdown-item>

                <b-dropdown-item
                  @click="deleteData(row.item.proId, row.item.cusId)"
                >
                  <feather-icon icon="PowerIcon" />
                  <span class="align-middle ml-50">Restart</span>
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>
        </b-col>

        <b-col md="2" sm="4" class="my-1">
          <b-form-group class="mb-0">
            <label class="d-inline-block text-sm-left mr-50">{{ $t("Per page") }}</label>
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              :options="pageOptions"
              @change="perPageChanged()"
              class="w-50"
            />

            <div class="show-entries" v-if="totalRows == 0">
              <label class="text-muted">{{ $t("Showing") }} 0 {{ $t("To") }}  0 {{ $t("Of") }}  0 {{ $t("Entries") }} </label>
            </div>
            <div class="show-entries" v-else>
              <div v-if="currentPage * perPage > totalRows">
                <label class="text-muted"
                  >{{ $t("Showing") }} {{ currentPage * perPage + 1 - perPage }} {{ $t("To") }}
                  {{ currentPage * perPage }} {{ $t("Of") }} {{ totalRows }} {{ $t("Entries") }}</label
                >
              </div>
              <div v-else>
                <label class="text-muted"
                  >{{ $t("Showing") }} {{ currentPage * perPage + 1 - perPage }} {{ $t("To") }}
                  {{ currentPage * perPage }} {{ $t("Of") }} {{ totalRows }} {{ $t("Entries") }}</label
                >
              </div>
            </div>
          </b-form-group>
        </b-col>

        <b-col md="10" sm="8">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            @click.native="handlePageChange()"
            align="right"
            size="sm"
            class="my-0"
          />
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
/* eslint-disable */
import {
  BCard,
  BCardHeader,
  BCardText,
  BLink,
  BTable,
  BFormCheckbox,
  BAvatar,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BImg,
  BDropdown,
  BDropdownItem,
  BCardBody,
} from "bootstrap-vue";
import jwt_decode from "jwt-decode";
import productService from "@/services/productService.js";

import { ref } from "@vue/composition-api";
import Ripple from "vue-ripple-directive";
import dateFormat from "dateformat";

export default {
  components: {
    BCard,
    BCardHeader,
    BCardText,
    BLink,
    BTable,
    BFormCheckbox,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BImg,
    BDropdown,
    BDropdownItem,
    BCardBody,
  },
  directives: {
    Ripple,
  },
  setup() {
    return {
      // Sidebar
    };
  },
  data() {
    return {
      perPage: 20,
      pageOptions: [20, 30, 50, 100],
      totalRows: 1,
      currentPage: 1,
      currentPagetable: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      fields: [
        {
          key: "show_details",
          label: "รายละเอียด",
          class: "d-sm-none",
        },
        // {
        //   key: "proDate",
        //   label: "Date",
        //   sortable: true,
        //   class: "text-left",
        // },
        {
          key: "proCode",
          label: "Code",
          class: "text-left",
        },
        {
          key: "cusName",
          label: "Customer",
          class: "text-left",
        },

        {
          key: "proState",
          label: "Status",
          class: "text-center",
        },
        {
          key: "actions",
          label: this.$t('Actions'),
          class: "text-center",
        },
      ],
      filterAll: "",
      filterSearch: "",
      filterDate: "",
      filterperPage: "",
      filtersortBy: "",
      filtersortDesc: "",
      bigdata: [],
      json_data: [],
      formData: [],
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  mounted() {},
  methods: {
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    updateDate() {
      var start = dateFormat(this.dateRange.startDate, "yyyy-mm-dd");
      var end = dateFormat(this.dateRange.endDate, "yyyy-mm-dd");
      if (this.dateRange.startDate && this.dateRange.endDate) {
        this.filterDate = "&startDate=" + start + "&endDate=" + end;
        this.getbigdata();
      }
    },
    searchEnter() {
      if (this.filter) {
        this.filterSearch = "&search=" + this.filter;
      } else {
        this.filterSearch = "";
      }
      this.getbigdata();
    },
    searchClear() {
      this.filter = "";
      this.filterSearch = "";
      this.getbigdata();
    },
    perPageChanged() {
      this.filterperPage = "&per_page=" + this.perPage;
      this.getbigdata();
    },
    sortingChanged(e) {
      if (e.sortBy) {
        this.filtersortBy = "&sortBy=" + e.sortBy;
      }

      if (e.sortDesc) {
        this.filtersortDesc = "&sortDesc=DESC";
      } else {
        this.filtersortDesc = "&sortDesc=ASC";
      }
      this.getbigdata();
    },
    handlePageChange() {
      this.getbigdata();
    },
    getbigdata() {
      var page = "page=" + this.currentPage;
      this.filterAll =
        "?" +
        page +
        this.filterSearch +
        this.filterperPage +
        this.filtersortBy +
        this.filtersortDesc;

      try {
        productService
          .getdata(this.filterAll)
          .then((response) => {
            this.bigdata = response.data.data.data;
            this.totalRows = response.data.data.total;
            console.log(this.bigdata);
          })
          .catch(() => {});

        // this.data1 = response.data;
        // console.log(this.User_data);
      } catch (e) {
        // console.log(e);
      }
    },

    refetchData() {
      this.getbigdata();
    },
    showtoken() {
      var token = localStorage.getItem("token");
      var decoded = jwt_decode(token);
      // console.log(decoded);
      // console.log(decoded.role);
      if (decoded.role == "superadmin") {
        this.role = true;
      } else {
        this.role = false;
      }
    },
  },
  async created() {
    await this.getbigdata();
    await this.showtoken();
  },
};
</script>

<style></style>
