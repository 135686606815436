<template>
  <b-row class="match-height">
    <b-col>
      <b-card>
        <b-card-header>
          <h1 class="font-midium-1 font-weight-bolder text-danger">
            Discredit
          </h1>
          <!-- <b-col lg="3" sm="6">
            <b-avatar size="48" variant="success">
              <img
                style="width: 2.5rem; height: 2.5rem"
                src="../../assets/images/money.png"
              /> </b-avatar
          ></b-col> -->
        </b-card-header>
        <b-card-body
          ><b-button-toolbar
            class="demo-inline-spacing"
            aria-label="Toolbar with button groups and input groups"
          >
            <b-input-group size="sm" append="บาท" prepend="$">
              <b-form-input value="100" class="text-right" />
            </b-input-group>
            <b-button-group size="sm">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
              >
                Save
              </b-button>
              <!-- <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
              >
                Cancel
              </b-button> -->
            </b-button-group>
          </b-button-toolbar></b-card-body
        >
      </b-card>
    </b-col>
  </b-row>
</template>
<script>
import {
  BCardBody,
  BMedia,
  BMediaAside,
  BMediaBody,
  BCard,
  BRow,
  BCol,
  BButton,
  BDropdown,
  BDropdownItem,
  BProgress,
  BCardText,
  BCardHeader,
  BLink,
  BTable,
  BFormCheckbox,
  BBadge,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BAvatar,
  BImg,
  BCardTitle,
  BButtonToolbar,
  BButtonGroup,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import Ripple from "vue-ripple-directive";
//import { $themeColors } from "@themeConfig";
import { kFormatter } from "@core/utils/filter";
//import jwt_decode from "jwt-decode";
import DateRangePicker from "vue2-daterange-picker";
//import reportService from "@/services/reportService.js";
//import vehiclesService from "@/services/vehiclesService.js";
//import customerService from "@/services/customerService.js";

import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
//import dateFormat from "dateformat";
//import cusDataService from "@/services/cusDataService";
import StatisticCard from "@core/components/statistics-cards/StatisticCardVertical.vue";

export default {
  components: {
    VueApexCharts,
    StatisticCard,
    BCard,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BMedia,
    BCardBody,
    BRow,
    BCol,
    BButton,
    BDropdown,
    BDropdownItem,
    BProgress,
    BCardText,
    BCardText,
    BCardHeader,
    BCardHeader,

    BLink,
    BTable,
    BFormCheckbox,

    BBadge,

    BFormGroup,
    BFormSelect,
    BPagination,
    BMediaAside,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BCardTitle,
    BButtonToolbar,
    BButtonGroup,

    BImg,
    DateRangePicker,
  },
  directives: {
    Ripple,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    kFormatter,
  },
};
</script>
